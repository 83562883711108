<template>
    <div id="form-component">
        <section class="form-header">
            <h3 class="form-title">{{ title }}</h3>
            <svg @click="close_popup" class="close" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#121212"/>
                <rect width="2.39765" height="19.9804" transform="matrix(0.70357 0.710626 -0.70357 0.710626 22.3125 8.09753)" fill="white"/>
                <rect width="2.39765" height="19.9804" transform="matrix(0.70357 -0.710626 0.70357 0.710626 8 9.70383)" fill="white"/>
            </svg>
        </section>

        <section v-if="!thank_you.display" class="form-content">
            <div class="container">
                <p v-html="desc" class="form-desc form-text"></p>
                <form id="my-form-content" class="my-form-content" @submit.prevent="submitForm" novalidate>
                    <div class="full-width-input f1">
                        <label for="form_field1" class="form-label">{{ field1_label }}</label>
                        <input type="text" id="form_field1" v-model="form.field1.value" v-on:change="watch_input1">
                    </div>
                    <div class="full-width-input f2">
                        <label for="form_field2" class="form-label">{{ field2_label }}</label>
                        <textarea rows="4" id="form_field2" v-model="form.field2.value" v-on:change="watch_input2"></textarea>
                    </div>

                    <div class="my-conditions">
                        <input type="checkbox" id="conditions" v-model="form.conditions.validation">
                        <span id="checkmark" class="checkmark"></span>
                        <label for="conditions" class="form-label">
                            {{ conditions_label_l1 }}
                            <span id='conditions-link' @click="open_conditions" v-html="conditions_label_l2"></span>
                            {{ conditions_label_l3 }}
                        </label>
                    </div>

                    <div v-if="!form.conditions.validation" class="submit-btn form-text">{{ submit_btn }}</div>
                    <button v-else id="submit-btn" type="submit" :value="submit_btn" name="submit" class="submit-btn form-text correct">{{ submit_btn }}</button>

                    <div class="post-response-msg form-label" v-if="post_response">{{ post_response}}</div>

                    <vue-recaptcha
                        ref="recaptcha"
                        @verify="onCaptchaVerified"
                        @expired="onCaptchaExpired"
                        size="invisible"
                        sitekey="6Lc9ttAeAAAAAP6t2y_Hsasn8VuDwkaeT648nyGw">
                    </vue-recaptcha>

                </form>

                <transition name="fade-and-slide">
                    <Conditions v-if="show_conditions" @close_conditions_is_clicked="close_conditions"/>
                </transition>

            </div>
        </section>

        <section v-else class="form-content">
            <div class="container c-ok">
                <img class="smiley" src="../assets/svg/smiley2.svg" alt="Smiley">
                <p v-html="thank_you_message" class="thankyou-desc form-text"></p>

                <router-link :to="{ name: 'Challenges' }">
                    <button @click="close_popup" class="submit-btn thankyou-btn form-text correct">{{ thank_you_continue }}</button>
                </router-link>

            </div>
        </section>

    </div>
</template>

<script setup>
    const host = window.location.host;
    const lang = host.indexOf("canton-fr") != -1 ? "fr" : "en";

    var title = "Votre défi";
    var desc = "Envoyez-nous votre défi, il pourrait être ajouté au&nbsp;jeu!";
    var field1_label = "Votre nom*";
    var field2_label = "Votre défi*";
    var conditions_label_l1 = "J’ai compris et j’accepte";
    var conditions_label_l2 = "les modalités de&nbsp;soumission";
    var conditions_label_l3 = "de mon défi.*";
    var submit_btn = "Envoyez!";
    var thank_you_message = "Bien reçu! Merci pour votre bonne&nbsp;idée.";
    var thank_you_continue = "Continuez à jouer";

    if(lang == "en"){
        title = "Your challenge";
        desc = "Send us your challenge, and it could be added to the&nbsp;game!";
        field1_label = "Your name*";
        field2_label = "Your challenge*";
        conditions_label_l1 = "I understand and agree to the";
        conditions_label_l2 = "terms and conditions";
        conditions_label_l3 = "of my challenge.*";
        submit_btn = "Send!";
        thank_you_message = "Challenge received. Thanks for&nbsp;sharing!";
        thank_you_continue = "Keep playing";
    }
</script>

<script>
    import Conditions from '@/components/Conditions.vue'
    import axios from 'axios'
    import { VueRecaptcha } from 'vue-recaptcha';

    export default {
        data() {
            return {
                form: {
                    field1 :{
                        value: null,
                        validation: false
                    },
                    field2: {
                        value: null,
                        validation: false
                    },
                    conditions: {
                        validation: false
                    },
                    form_is_correct: false
                },
                thank_you: {
                    display: false
                },
                show_conditions: false,
                post_response: null,
                recaptchaToken: null
            }
        },
        components: {
            Conditions,
            VueRecaptcha
        },
        methods: {
            watch_input1(){ 
                if(!this.form.field1.value){
                    document.getElementById('form_field1').classList.add("has-error");
                    this.form.field1.validation = false;
                } else {
                    document.getElementById('form_field1').classList.remove("has-error");
                    this.form.field1.validation = true;
                }
            },
            watch_input2(){ 
                if(!this.form.field2.value){
                    document.getElementById('form_field2').classList.add("has-error");
                    this.form.field2.validation = false;
                } else {
                    document.getElementById('form_field2').classList.remove("has-error");
                    this.form.field2.validation = true;
                }
            },
            watch_input3(){ 
                if(!this.form.conditions.validation){
                    document.getElementById('checkmark').classList.add("has-error");
                    this.form.conditions.validation = false;
                } else {
                    document.getElementById('checkmark').classList.remove("has-error");
                    this.form.conditions.validation = true;
                }
            },
            checkForm(){
                this.watch_input1();
                this.watch_input2();
                this.watch_input3();
                if(this.form.field1.validation && this.form.field2.validation && this.form.conditions.validation){
                    this.form.form_is_correct = true;
                }
            },
            
            
            onCaptchaVerified(recaptchaToken){
                this.$refs.recaptcha.reset();
                this.recaptchaToken = recaptchaToken;

                const host = window.location.host;
                const lang = host.indexOf("canton-fr") != -1 ? "fr" : "en";

                axios.post("services/send.php", { 
                    name: this.form.field1.value, 
                    challenge: this.form.field2.value, 
                    lang: lang,
                    'g-recaptcha-response': this.recaptchaToken
                })
                .then((res) => {
                    if(res.data.status == "success"){
                        this.thank_you.display = true;
                        this.post_response = null;
                    } else {
                        this.post_response = res.data.message;
                    }
                })
                .catch((err) => {
                    console.error(err);
                });

            },
            onCaptchaExpired(){
                this.$refs.recaptcha.reset();
            },


            submitForm(){
                this.checkForm();
                if(this.form.form_is_correct){
                    this.$refs.recaptcha.execute();
                }
            },
            resetForm(){
                this.form.field1.value = null;
                this.form.field1.validation = false;
                this.form.field2.value = null;
                this.form.field2.validation = false;
                this.form.conditions.validation = false;
                this.form.form_is_correct = false;
                this.thank_you.display = false;
            },
            close_popup(){
                this.resetForm();
                document.getElementById('home').classList.remove('small-opacity');
                document.body.classList.remove('no-scroll');
                this.$store.state.display_form_popup = false;
            },
            open_conditions(){
                this.show_conditions = true;
            },
            close_conditions(){
                this.show_conditions = false;
            }
        }
    }
</script>


<style scoped>
    #form-component{
        position: fixed;
        top: 0;
        background: var(--white);
        width: 100%;
        z-index: 999999;
        height: 100vh;
        overflow: scroll;
        -ms-overflow-style: none; /* IE and EDGE */;
        scrollbar-width: none; /* Firefox */
    }

    .form-header{
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        background: var(--white);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.8rem;
        border-bottom: solid var(--black) 1px;
        z-index: 9;
    }

    .form-title{
        font-family: var(--secondary-font);
        font-size: 2.1rem;
        line-height: 3.3rem;
        text-transform: uppercase;
        color: var(--black);
        transform: translateY(0.15rem);
    }

    svg.close{
        cursor: pointer;
        transition: all ease 0.4s;
        -webkit-tap-highlight-color: transparent;
    }

    .form-content{
        margin-top: 7rem;
        padding: 3.4rem 2.1rem 6rem;
    }

    .form-desc{
        width: 23rem;
        margin: 0 auto;
        text-align: center;
    }

    .my-form-content{
        padding-top: 3.2rem;
    }

    .full-width-input label{
        display: block;
        padding-left: 1.9rem;
    }

    .full-width-input input[type="text"],
    textarea{
        margin-top: 0.8rem;
        border: 1px solid var(--black);
        box-sizing: border-box;
        border-radius: 10rem;
        width: 100%;
        padding: 1.2rem 1.9rem;
        font-family: var(--forth-font);
        color: var(--black);
        font-size: 2.2rem;
        line-height: 2.7rem;
    }

    .has-error{
        background: #FFEBEB!important;
        border-color: #DC6666!important;
    }

    .f2{
        padding-top: 3rem;
    }

    textarea{
        height: 17.2rem;
        border-radius: 3rem;
        resize: none;
    }

    .my-conditions{
        position: relative;
        padding-top: 2rem;
        display: flex;
        align-items: center;
    }

    .my-conditions input{
        display: none;
    }

    .checkmark{
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        border: 1px solid var(--black);
        border-radius: 0.6rem;
        background: var(--white);
    }

    .my-conditions input:checked ~ .checkmark:before {
        position: absolute;
        content: "";
        width: 1.4rem;
        height: 1.4rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: var(--black);
        border-radius: 50%;
    }

    .my-conditions label{
        position: relative;
        padding-left: 3.6rem;
    }

    .my-conditions label a{
        text-decoration: underline;
    }

    .submit-btn{
        margin: 5.4rem auto 0;
        background: var(--black);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6rem;
        width: 24.5rem;
        height: 7.9rem;
        font-family: var(--forth-font);
        color: var(--white);
        cursor: pointer;
        border: none;
        opacity: 0.2;
    }

    .submit-btn.correct{
        opacity: 1;
        cursor: pointer;
    }

    .smiley{
        display: block;
        width: 17.2rem;
        margin: 0.7rem auto 0;
    }

    .thankyou-desc{
        width: 23rem;
        padding-top: 3.8rem;
        margin: 0 auto;
        text-align: center;
    }

    .thankyou-btn{
        margin-top: 3.5rem;
    }

    #conditions-link{
        cursor: pointer;
        text-decoration: underline;
        transition: all ease 0.4s;
    }

    .post-response-msg{
        padding-top: 1.5rem;
        text-align: center;
        color: #dc6666;
    }

    .fade-and-slide-enter-from {
        opacity: 0;
        transform: translateY(2rem);
    }
    .fade-and-slide-enter-to,
    .fade-and-slide-leave-from {
        opacity: 1;
        transform: none;
    }
    .fade-and-slide-leave-to {
        opacity: 0;
        transform: translateX(-5rem);
    }
    .fade-and-slide-enter-active,
    .fade-and-slide-leave-active {
        transition: all ease 0.4s;
    }

    @media screen and (min-width: 992px){
        #form-component{
            width: 37.4rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 2rem;
            height: auto;
            overflow-x: hidden;
            overflow-y: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;
        }

        #form-component::-webkit-scrollbar{
            display: none;
        }

        svg.close:hover{
            transform: rotate(90deg);
        }

        #conditions-link:hover{
            color: #9a9999;
        }

        .form-header{
            border-top-right-radius: 2rem;
            border-top-left-radius: 2rem;
        }

        .form-title{
            transform: translateY(0.35rem);
        }
    }

    @media screen and (min-width: 992px) and (max-height: 800px){
        #form-component{
            position: fixed;
            top: 2rem;
            left: 50%;
            background: var(--white);
            width: 37.4rem;
            height: calc(100vh - 4rem);
            transform: translateX(-50%);
            overflow: hidden;
        }

        .container{
            overflow-y: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;
            height: 74.4rem;
            box-sizing: border-box;
            scrollbar-width: none;
            -ms-overflow-style: none;
        }

        .container::-webkit-scrollbar{
            display: none;
        }

        .container.c-ok{
            height: 52rem;
        }

        .form-content{
            margin-top: 0;
            padding-top: 10.4rem;
            height: calc(100vh - 4rem);
            overflow: scroll;
            -ms-overflow-style: none; /* IE and EDGE */;
            scrollbar-width: none; /* Firefox */
        }
    }

</style>