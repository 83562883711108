<template>
    <div id="conditions-content">

        <section class="form-header">
            <svg @click="close_conditions_popup" class="close" width="32" height="32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 490.4 490.4" style="enable-background:new 0 0 490.4 490.4;" xml:space="preserve">
                <path d="M490.4,245.2C490.4,110,380.4,0,245.2,0S0,110,0,245.2s110,245.2,245.2,245.2S490.4,380.4,490.4,245.2z M24.5,245.2
                    c0-121.7,99-220.7,220.7-220.7s220.7,99,220.7,220.7s-99,220.7-220.7,220.7S24.5,366.9,24.5,245.2z"/>
                <path d="M253.9,360.4l68.9-68.9c4.8-4.8,4.8-12.5,0-17.3s-12.5-4.8-17.3,0l-48,48V138.7c0-6.8-5.5-12.3-12.3-12.3
                    s-12.3,5.5-12.3,12.3v183.4l-48-48c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l68.9,68.9c2.4,2.4,5.5,3.6,8.7,3.6
                    S251.5,362.8,253.9,360.4z"/>
            </svg>
            <h3 class="paragraph1 conditions-title">{{ title }}</h3>
        </section>

        <section class="conditions-text">
            <p class="paragraph3" v-html="text"></p>
        </section>

    </div>
</template>


<script setup>
    const host = window.location.host;
    const lang = host.indexOf("canton-fr") != -1 ? "fr" : "en";

    var title = "Conditions";
    var text = "";
    if(lang == "fr"){
        text = "En soumettant votre suggestion d’idée de défi, (1) vous déclarez qu’à votre connaissance, il s’agit d’une idée originale qui ne copie et ne viole pas les droits de propriété intellectuelle de quiconque, et (2) vous octroyez, à titre gracieux, à Industries Lassonde Inc. et à ses filiales une licence non-exclusive, libre de redevance, mondiale, perpétuelle, irrévocable, transférable, et avec droit de sous-licence (à plusieurs niveaux), permettant d’utiliser, copier, reproduire, modifier, adapter, traduire, diffuser publiquement, créer des œuvres dérivées et autrement exploiter votre idée pour quelques fins que ce soit, incluant une renonciation à vos droits moraux à cet égard, mais sans obligation qu’Industries Lassonde Inc. et ses filiales utilisent votre&nbsp;idée.";
    } else {
        text = "By submitting your suggestion of an idea for a challenge, (1) you represent that to your knowledge, it is an original idea that does not copy or infringe anyone’s intellectual property rights, and (2) you grant, free of charge, to Lassonde Industries Inc. and its affiliates a non-exclusive, royalty-free, worldwide, perpetual, irrevocable, transferable and sublicensable (through multiple tiers) licence to use, copy, reproduce, modify, adapt, translate, publicly display, create derivative works of and otherwise exploit your idea for any purpose whatsoever, including a waiver of your moral rights thereto, but without any obligation for Lassonde Industries Inc. and its affiliates to use your&nbsp;idea.";
    }

    const emit = defineEmits(["close_conditions_is_clicked"]);

    function close_conditions_popup() {
        emit("close_conditions_is_clicked");
    }
</script>


<style scoped>
    #conditions-content{
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--white);
        display: flex;
        box-sizing: border-box;
        z-index: 9999;
        overflow-y: scroll;
    }

    .form-header{
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        background: var(--white);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 1.8rem;
        border-bottom: solid var(--black) 1px;
        border-top-right-radius: 2rem;
        border-top-left-radius: 2rem;
    }

    .form-title{
        font-family: var(--secondary-font);
        font-size: 2.1rem;
        line-height: 3.3rem;
        text-transform: uppercase;
        color: var(--black);
        transform: translateY(0.15rem);
    }

    svg.close{
        cursor: pointer;
        transition: all ease 0.4s;
        transform: rotate(90deg);
        -webkit-tap-highlight-color: transparent;
    }

    .conditions-text{
        padding: 11rem 2.1rem 6rem;
        background: var(--white);
    }

    .conditions-title{
        margin-left: 2rem;
    }

    @media screen and (min-width: 992px) and (max-height: 800px){
        .paragraph3{
            padding-bottom: 4rem;
        }
    }

    @media screen and (min-width: 992px){
        #conditions-content{
            border-radius: 2rem;
            -ms-overflow-style: none;
            scrollbar-width: none;
        }

        #conditions-content::-webkit-scrollbar {
            display: none;
        }
    }

</style>