<template>
    <div id="challenges">
        <div class="cover" v-if="showTransition"></div>
        <div id="challenge-container" class="container">
            <section id="challenge-top" class="challenge-top">
                <h3 class="number">Nº{{ challenge.number }}</h3>
                <router-link :to="{ name: 'Home' }">
                    <svg class="close" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16" cy="16" r="16" fill="#121212"/>
                        <rect width="2.39765" height="19.9804" transform="matrix(0.70357 0.710626 -0.70357 0.710626 22.3125 8.09753)" fill="white"/>
                        <rect width="2.39765" height="19.9804" transform="matrix(0.70357 -0.710626 0.70357 0.710626 8 9.70383)" fill="white"/>
                    </svg>
                </router-link>
            </section>
            <section id="challenge-content" class="challenge-content" data-simplebar>
                <span v-html="challenge.category" class="category"></span>
                <p v-html="challenge.challenge_desc" class="challenge-description"></p>
            </section>

            <NextChallenge @click="load_new_challenge" v-bind:class="{ noClick: showTransition }" />

            <lottie-animation v-if="showTransition"
                ref="customControl"
                class="transition"
                path="transition.json"
                :loop="false"
                @AnimControl="setAnimController" />
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import NextChallenge from '@/components/NextChallenge.vue'
    import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
    import axios from 'axios'

    export default {
        data() {
            return {
                showTransition: true,
                all_challenges: null,
                keys: null,
                randIndex: null,
                challenge: {
                    number: "",
                    category: "",
                    challenge_desc: ""
                }
            }
        },
        components: {
            NextChallenge,
            LottieAnimation
        },
        methods: {

            setAnimController: function (anim) {
                this.anim = anim;
            },

            play: function () {
                this.showTransition = true;
                this.anim.play();
            },

            stop: function () {
                this.showTransition = false;
                this.anim.stop();
            },

            async custom_animation() {
                this.showTransition = false;
                this.play();
                setTimeout(() => {
                    this.change_background_color();
                    setTimeout(() => {
                        this.stop();
                    }, 600)
                }, 1000)
            },

            change_background_color() {
                const colors = ['#FFC839', '#FEAD47', '#D0917A', '#E78278', '#DF738D', '#DC84C3', '#61CE9C', '#A4E1AA', '#8DDAD5', '#87BAF8', '#7D90F4', '#A99EF1'];
                var random_color = colors[Math.floor(Math.random() * colors.length)];
                document.getElementById('challenges').style.backgroundColor = random_color;
                document.getElementById('challenge-container').style.backgroundColor = random_color;
                document.getElementById('challenge-top').style.backgroundColor = random_color;
            },

            load_new_challenge() {

                this.custom_animation();
                
                setTimeout(() => {

                    const host = window.location.host;
                    const lang = host.indexOf("canton-fr") != -1 ? "fr" : "en";

                    // Generate random index based on number of keys
                    this.randIndex = Math.floor(Math.random() * this.keys.length);

                    // Get challenge infos
                    this.challenge.number = this.all_challenges[this.randIndex].number;
                    this.challenge.category = this.all_challenges[this.randIndex].category_en;
                    this.challenge.challenge_desc = this.all_challenges[this.randIndex].challenge_en;
                    if(lang == "fr"){
                        this.challenge.category = this.all_challenges[this.randIndex].category_fr;
                        this.challenge.challenge_desc = this.all_challenges[this.randIndex].challenge_fr;
                    }

                }, 1000)

            }
        },
        mounted() {

            this.change_background_color();
            window.scrollTo(0, 0);
            axios.get('https://cantoncanada.com/wp-json/canton_challenges/challenges')

                .then(response => {
                    
                    const host = window.location.host;
                    const lang = host.indexOf("canton-fr") != -1 ? "fr" : "en";

                    this.all_challenges = response.data;

                    // Create array of object keys
                    this.keys = Object.keys(this.all_challenges);

                    // Generate random index based on number of keys
                    this.randIndex = Math.floor(Math.random() * this.keys.length);

                    this.challenge.number = this.all_challenges[this.randIndex].number;
                    this.challenge.category = this.all_challenges[this.randIndex].category_en;
                    this.challenge.challenge_desc = this.all_challenges[this.randIndex].challenge_en;
                    if(lang == "fr"){
                        this.challenge.category = this.all_challenges[this.randIndex].category_fr;
                        this.challenge.challenge_desc = this.all_challenges[this.randIndex].challenge_fr;
                    }

                    setTimeout(() => {
                        this.showTransition = false;
                    }, 1000)

                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }
</script>

<style scoped>
    #challenges{
        position: relative;
        overflow: hidden;
        background: var(--black);
        transition: opacity ease 0.5s;
    }

    .cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: transparent;
        z-index: 9;
    }

    .container{
        height: 100vh;
        overflow-y: scroll;
        background: var(--orange);
        transition: all ease 1s;
        animation: custom_reveal_opacity 1.5s ease 0.2s both;
    }
    
    .challenge-top{
        position: fixed;
        top: 0;
        width: 100%;
        box-sizing: border-box;
        background: var(--orange);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.8rem 3rem;
        border-bottom: solid var(--black) 1px;
        z-index: 9;
        transition: all ease 1s;
    }

    .number{
        font-family: var(--secondary-font);
        font-size: 2.1rem;
        line-height: 3.3rem;
        text-transform: uppercase;
        color: var(--black);
        transform: translateY(0.15rem);
    }

    .challenge-content{
        position: relative;
        background: transparent;
        color: var(--black);
        transition: all ease 1s;
        height: 100vh;
        margin: 7.2rem 0.6rem 0 3rem;
        overflow-y: scroll;
        height: calc(100vh - 15.3rem - 7.2rem);
        -ms-overflow-style: none; /* IE and EDGE */;
        scrollbar-width: none; /* Firefox */
    }

    .challenge-content::-webkit-scrollbar {
        width: 0.6rem;
    }

    .challenge-content::-webkit-scrollbar-track {
        background: transparent;
    }

    .challenge-content::-webkit-scrollbar-thumb {
        background: var(--grey);
        border-radius: 5rem;
    }

    .category{
        padding-top: 2rem;
        padding-right: 1.5rem;
        display: block;
        font-family: var(--tertiary-font);
        font-size: 1.8rem;
        line-height: 5.4rem;
        color: var(--black);
    }

    .challenge-description{
        font-family: var(--primary-font);
        font-size: 5rem;
        line-height: 5.4rem;
        color: var(--black);
        padding: 2rem 1.5rem 13rem 0;
    }

    .transition{
        position: absolute;
        top: initial;
        bottom: 10rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        min-width: 100%!important;
        overflow: visible!important;
        height: auto!important;
        box-sizing: border-box;
    }

    .close{
        cursor: pointer;
        transition: all ease 0.4s;
        -webkit-tap-highlight-color: transparent;
    }

    @media screen and (max-height: 700px){

        .challenge-content{
            height: calc(73.4rem - 29rem);
        }

        .challenge-description{
            font-size: 4.5rem;
            line-height: 5rem;
            padding-bottom: 6rem;
        }
    }


    @media screen and (min-width: 768px) and (max-width: 991px){
        .challenge-top{
            padding-left: 5rem;
            padding-right: 5rem;
        }

        .challenge-content{
            margin-left: 5rem;
        }
    }

    @media screen and (min-width: 992px){

        #challenges{
            background: transparent;
            background-image: url("../assets/images/ingredient-background.jpg");
            width: 100%;
            height: 100vh;
            background-size: cover;
            background-position: center;
        }

        .container{
            position: fixed;
            width: 37.4rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 2rem;
            height: 73.4rem;
            overflow: hidden;
            animation: custom_reveal_bottom_scale 1.5s ease 0.2s both;
        }
        
        .challenge-content{
            overflow-y: scroll;
            height: calc(73.4rem - 15.3rem - 6.6rem);
        }

        .close:hover{
            transform: rotate(90deg);
        }
    }

   @media screen and (min-width: 992px) and (max-height: 800px){
        .container{
            position: fixed;
            top: 2rem;
            left: 50%;
            width: 37.4rem;
            height: calc(100vh - 4rem);
            transform: translateX(-50%);
        }

        .challenge-content{
            height: calc(100vh - 22.8rem);
        }
    }

</style>