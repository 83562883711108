<template>
    <div id="next-challenge">
        <img class="smiley" src="../assets/svg/smiley.svg" alt="Smiley">
        <div class="text-content form-text">{{ text }}</div>
    </div>
</template>


<script setup>
    const host = window.location.host;
    const lang = host.indexOf("canton-fr") != -1 ? "fr" : "en";

    var text = "Encore un autre!";
    if(lang == "en"){
        text = "One more!";
    }
</script>


<style scoped>
    #next-challenge{
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        cursor: pointer;
        z-index: 99;
        -webkit-tap-highlight-color: transparent;
    }

    .smiley{
        position: absolute;
        bottom: 11.9rem;
        left: 50%;
        width: 10.1rem;
        transform: translateX(-50%);
        margin: 0 auto;
        animation: rotation 6s infinite linear;
    }

    #next-challenge:before{
        position: absolute;
        content: "";
        width: 16.1rem;
        height: 16.1rem;
        background: var(--white);
        border-radius: 50%;
        bottom: 8.9rem;
        left: 50%;
        transform: translateX(-50%);
        transition: all ease 0.4s;
    }

    #next-challenge.noClick{
        cursor: initial;
    }

    #next-challenge.noClick:before{
        animation: animateCircle 0.8s ease;
    }

    @keyframes animateCircle {
        0% {
            transform: translateX(-50%);
        }
        50% {
            transform: translateX(-50%) scale(1.1);
        }
        100% {
            transform: translateX(-50%);
        }
    }
    
    .text-content{
        padding: 8.7rem 2rem 4.4rem;
        color: var(--white);
        text-align: center;
        background: var(--black);
    }

    @keyframes rotation {
        from {
            transform: translateX(-50%) rotate(0deg);
        }
        to {
            transform: translateX(-50%) rotate(359deg);
        }
    }

    @media screen and (max-height: 700px){
        #next-challenge:before{
            width: 11rem;
            height: 11rem;
            bottom: 7rem;
        }

        .smiley{
            bottom: 8.75rem;
            width: 7.5rem;
        }

        .text-content{
            padding: 6.5rem 2rem 3rem;
        }
    }
</style>